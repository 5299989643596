const sectionObserver = new IntersectionObserver((entries) => {
  entries.forEach(({ target, isIntersecting }) => {
    target.classList.toggle('is-inViewport', isIntersecting)
  })
}, {
  rootMargin: '-10% 0px',
})

const spacerObserver = new IntersectionObserver((entries) => {
  entries.forEach(({ target, isIntersecting }) => {
    const { firstElementChild } = target
    target.classList.toggle('is-inViewport', isIntersecting)

    if (firstElementChild.classList.contains('spacer-video')) {
      const video = firstElementChild.querySelector('video')
      if (isIntersecting) {
        video.currentTime = (video.ended) ? 0 : video.currentTime
        video.play()
      }
      else {
        video.pause()
      }
    }
  })
})

document.querySelectorAll('.card')
  .forEach(element => {
    sectionObserver.observe(element)
    element.querySelector('.card-image').addEventListener('touchstart', (event) => {
      event.preventDefault()
      element.querySelector('.card-image').classList.toggle('touched')
      // currentTarget.classList.toggle('touched', currentTarget.classList.has('toched'))
    })
  })


document.querySelectorAll('.spacer')
  .forEach(element => spacerObserver.observe(element))

document.querySelector('.js-nav-toggle').addEventListener('click', ({ currentTarget }) => {
  const { classList } = currentTarget
  classList.toggle('is-open')
  document.querySelector('.js-nav').classList.toggle('is-open', classList.contains('is-open'))
})

document.querySelectorAll('.js-slider').forEach(slider => {
  const buttons = slider.querySelectorAll('.js-slider-control')
  buttons.forEach(button => {
    button.addEventListener('click', event => {
      buttons.forEach(({ classList, dataset }) => classList.toggle('is-active', button.dataset.index == dataset.index))
      slider.querySelector(`[data-index="${button.dataset.index}"]`).scrollIntoView()
    })
  })
})
